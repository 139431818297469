@font-face {
  font-family: HelveticaNeue;
  src: url("../../assets/fonts/HelveticaNeue.ttf"); }

body {
  margin: 0;
  font-size: 1rem;
  font-family: HelveticaNeue; }

* {
  box-sizing: border-box; }

.no-scroll {
  overflow: hidden; }

.header {
  padding: 2.75rem 2rem;
  display: flex;
  justify-content: space-between; }
  .header .logo-container {
    width: 2.5rem;
    height: 2.5rem; }
    .header .logo-container .logo {
      width: 100%;
      height: auto;
      cursor: pointer; }
  .header .nav-container {
    display: flex;
    align-items: center; }
    .header .nav-container .link {
      margin: 0 1.5rem;
      cursor: pointer;
      color: #555555;
      text-decoration: none; }
    .header .nav-container .active, .header .nav-container .link:hover {
      font-weight: bold; }
      @media screen and (max-width: 990px) {
        .header .nav-container .active, .header .nav-container .link:hover {
          font-weight: unset; } }
    @media screen and (max-width: 990px) {
      .header .nav-container .hideMobile {
        display: none; } }

.service-form {
  position: relative; }
  .service-form .row {
    display: flex;
    justify-content: space-between; }
    .service-form .row .input-container {
      width: 48%; }
  .service-form .text-input-container {
    position: relative; }
    .service-form .text-input-container .predictions-container {
      z-index: 20;
      padding: 1rem 1rem;
      width: 100%;
      background-color: white;
      position: absolute;
      border-radius: 0 0 1rem 1rem;
      color: #696969;
      display: flex;
      flex-direction: column;
      gap: .5rem; }
      .service-form .text-input-container .predictions-container .prediction {
        cursor: pointer; }
  .service-form .confirm-button {
    height: 3rem;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    color: #696969;
    text-align: center;
    cursor: pointer;
    background-color: white;
    margin-top: 2rem; }
  .service-form .overlay-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    z-index: 20;
    border-radius: 1rem; }
    .service-form .overlay-container .text {
      color: white; }
    .service-form .overlay-container .button {
      color: white;
      border-radius: 1rem;
      padding: .5rem 1rem;
      border: solid 1px #579485;
      text-decoration: none; }

.bool-switch {
  width: 100%;
  height: 100%; }
  .bool-switch .label {
    padding: 0 0 .5rem; }
  .bool-switch .option-row {
    height: 100%;
    color: #696969;
    display: flex; }
    .bool-switch .option-row .option-capsule {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      padding: 1rem 3rem;
      background-color: #a6c2bb;
      transition: all .2s ease;
      cursor: pointer; }
    .bool-switch .option-row .first {
      border-radius: 1rem 0 0 1rem; }
    .bool-switch .option-row .last {
      border-radius: 0 1rem 1rem 0; }
    .bool-switch .option-row .active {
      background-color: white; }

.date-picker .label {
  padding: .5rem 0; }

.date-picker .react-datepicker__current-month, .date-picker .react-datepicker__day-name {
  color: #696969; }

.date-picker .react-datepicker__day--selected {
  color: white;
  background-color: #a6c2bb; }

.date-picker .react-datepicker-wrapper input {
  height: 3rem;
  width: 100%;
  padding: 1rem 1rem;
  border-radius: 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  color: #696969;
  background-color: #a6c2bb; }

.date-picker .react-datepicker__header {
  background-color: #a6c2bb; }

.date-picker .react-datepicker__triangle::after, .date-picker .react-datepicker__triangle::before {
  display: none; }

.date-picker .react-datepicker__navigation-icon--next::before, .date-picker .react-datepicker__navigation-icon--previous::before {
  border-color: #696969; }

.styled-time-picker .label {
  padding: .5rem 0; }

.styled-time-picker .time-picker input {
  color: #696969;
  height: 3rem; }

.styled-text-input {
  width: 100%;
  /* Hide the default number input arrows */
  /* Disable the increment and decrement arrows */ }
  .styled-text-input .label {
    padding: .5rem 0; }
  .styled-text-input .text-input {
    height: 3rem;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    color: #696969;
    background-color: #a6c2bb;
    resize: none; }
  .styled-text-input textarea {
    min-height: 7rem; }
  .styled-text-input input[type="number"]::-webkit-inner-spin-button,
  .styled-text-input input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0; }
  .styled-text-input input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    /* Firefox */ }

.simple-image-carrousel {
  width: 100%;
  height: 100%; }
  .simple-image-carrousel .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .simple-image-carrousel .image-container img {
      width: 100%;
      object-fit: contain;
      max-height: 100%;
      transition: opacity 0.2s ease-in-out;
      opacity: 0; }
    .simple-image-carrousel .image-container .animate img {
      transition: opacity 0.2s cubic-bezier(0.5, 0.15, 0.25, 1); }
    .simple-image-carrousel .image-container .image.active {
      z-index: 1;
      opacity: 1; }
    .simple-image-carrousel .image-container .image:not(.active) {
      position: absolute;
      opacity: 0;
      z-index: 0;
      pointer-events: none; }
    .simple-image-carrousel .image-container .previous-icon,
    .simple-image-carrousel .image-container .next-icon {
      position: absolute;
      top: calc(50% - 2rem);
      height: 4rem;
      width: auto;
      cursor: pointer;
      z-index: 5; }
      .simple-image-carrousel .image-container .previous-icon img,
      .simple-image-carrousel .image-container .next-icon img {
        width: auto;
        height: 100%;
        opacity: 1;
        filter: invert(1); }
    .simple-image-carrousel .image-container .previous-icon {
      left: 0;
      transform: rotate(-90deg); }
    .simple-image-carrousel .image-container .next-icon {
      right: 0;
      transform: rotate(90deg); }
  .simple-image-carrousel .bubbles {
    display: flex;
    justify-content: center; }
    .simple-image-carrousel .bubbles .bubble {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      border-radius: 50%;
      background-color: #a0c2ba;
      cursor: pointer;
      transition: background-color 0.2s cubic-bezier(0.5, 0.15, 0.25, 1); }
    .simple-image-carrousel .bubbles .active {
      background-color: #e8f0ee; }

@keyframes fadeInOut {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.footer {
  width: 100%;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  flex-shrink: 0; }
  @media screen and (max-width: 1700px) {
    .footer {
      flex-direction: column;
      gap: 1rem; } }
  .footer .copyright {
    font-weight: bold;
    letter-spacing: 1px; }
  .footer .text {
    color: white; }
    .footer .text .link {
      cursor: pointer;
      text-decoration: none;
      color: inherit; }
  .footer .left {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 990px) {
      .footer .left {
        flex-direction: column;
        gap: 1rem; } }
    .footer .left .text {
      margin-right: 2rem; }
      @media screen and (max-width: 990px) {
        .footer .left .text {
          margin-right: 0; } }
  .footer .right {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 990px) {
      .footer .right {
        flex-wrap: wrap;
        justify-content: center; } }
    .footer .right .icon-row {
      display: flex;
      align-items: center;
      margin-right: 2rem; }
      @media screen and (max-width: 990px) {
        .footer .right .icon-row {
          width: 100%;
          justify-content: center;
          margin-right: 0;
          gap: 2rem;
          margin-bottom: 1rem; } }
      .footer .right .icon-row .icon {
        margin-left: 2rem;
        cursor: pointer; }
        @media screen and (max-width: 990px) {
          .footer .right .icon-row .icon {
            margin-left: 0; } }

.animated-image-carrousel {
  position: relative;
  width: 100%; }
  .animated-image-carrousel .carrousel-container {
    display: flex;
    overflow: hidden; }
  .animated-image-carrousel .panel-container {
    transition: all .3s ease;
    cursor: pointer;
    user-select: none;
    min-width: calc((100%) / 3);
    max-width: calc((100%) / 3);
    display: flex;
    align-items: center;
    height: 20rem; }
    @media screen and (max-width: 990px) {
      .animated-image-carrousel .panel-container {
        min-width: 100%;
        max-width: 100%; } }
  .animated-image-carrousel .bubbles {
    left: 0;
    right: 0;
    bottom: 1.5rem;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 10;
    gap: .5rem; }
    .animated-image-carrousel .bubbles .bubble {
      width: 0.625rem;
      height: 0.625rem;
      padding: 0.3125rem;
      border-radius: 50%;
      background-color: #b5b5b5;
      cursor: pointer;
      transition: background-color 0.2s cubic-bezier(0.5, 0.15, 0.25, 1); }
    .animated-image-carrousel .bubbles .active {
      background-color: #6c6c6c; }

.carrousel-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: all .25s ease; }
  .carrousel-panel .title {
    font-size: 1.5625rem;
    font-weight: bold;
    text-align: center; }
  .carrousel-panel .sub-title {
    font-size: 1.125rem;
    text-align: center; }
  .carrousel-panel .image-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .carrousel-panel .image-container img {
      max-height: 15rem;
      height: 90%;
      max-width: 80%;
      object-fit: contain;
      z-index: 5; }
    .carrousel-panel .image-container .background-panel {
      position: absolute;
      height: 65%;
      min-width: 100%;
      background: #ebebeb;
      border-radius: 1rem;
      transition: all .2s ease; }
    .carrousel-panel .image-container .green-bg {
      background: linear-gradient(270deg, #2c584a 0%, #579485 40%); }

.panel-carrousel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem; }
  .panel-carrousel .panels {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 30rem;
    width: calc(100% / 3); }
    @media screen and (max-width: 1700px) {
      .panel-carrousel .panels {
        height: 20rem; } }
    @media screen and (max-width: 990px) {
      .panel-carrousel .panels {
        height: 10rem;
        width: calc(100% / 2); } }
    .panel-carrousel .panels .panel {
      position: absolute;
      width: 100%;
      height: 30rem;
      aspect-ratio: 1/1;
      border-radius: 1rem;
      transition: all .2s ease;
      cursor: pointer; }
      @media screen and (max-width: 1700px) {
        .panel-carrousel .panels .panel {
          height: 20rem; } }
      @media screen and (max-width: 990px) {
        .panel-carrousel .panels .panel {
          height: 10rem; } }
    @media screen and (max-width: 990px) {
      .panel-carrousel .panels .hideMobile {
        display: none; } }
  .panel-carrousel .bubbles {
    position: absolute;
    bottom: -1.5rem;
    display: flex;
    justify-content: center;
    z-index: 15;
    gap: .5rem; }
    .panel-carrousel .bubbles .bubble {
      width: 0.625rem;
      height: 0.625rem;
      padding: 0.3125rem;
      border-radius: 50%;
      background-color: #b5b5b5;
      cursor: pointer;
      transition: background-color 0.2s cubic-bezier(0.5, 0.15, 0.25, 1); }
    .panel-carrousel .bubbles .active {
      background-color: #6c6c6c; }

.styled-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none; }
  .styled-checkbox .box {
    min-width: 1rem;
    min-height: 1rem;
    margin-right: 1rem;
    transition: all .2s ease;
    align-self: start; }

.conditional-step-icon {
  height: 100%;
  width: 100%;
  border-radius: 5rem;
  border: 1px solid #579485;
  display: flex;
  justify-content: center;
  align-items: center; }
  .conditional-step-icon .icon {
    height: 50%;
    width: 50%; }

.service-class-option-panel {
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr); }
  @media screen and (max-width: 1700px) {
    .service-class-option-panel {
      gap: 0; } }
  @media screen and (max-width: 990px) {
    .service-class-option-panel {
      grid-template-columns: repeat(1, 1fr); } }
  .service-class-option-panel .car-square-container {
    background-color: #579485;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0; }
    @media screen and (max-width: 1700px) {
      .service-class-option-panel .car-square-container {
        border-radius: 1rem 0 0 1rem; } }
    @media screen and (max-width: 990px) {
      .service-class-option-panel .car-square-container {
        border-radius: 1rem 1rem 0 0; } }
    .service-class-option-panel .car-square-container .option-capsule {
      padding: 1rem 2rem; }
    .service-class-option-panel .car-square-container .car {
      width: 110%;
      height: auto; }
      @media screen and (max-width: 1700px) {
        .service-class-option-panel .car-square-container .car {
          width: 90%; } }
      @media screen and (max-width: 990px) {
        .service-class-option-panel .car-square-container .car {
          width: 70%; } }
    .service-class-option-panel .car-square-container .switch-container {
      width: 90%;
      margin-top: 1rem; }
  .service-class-option-panel .detail-container {
    grid-column: span 2;
    background-color: #ebebeb;
    border-radius: 1rem;
    padding: 2rem;
    display: grid; }
    @media screen and (max-width: 1700px) {
      .service-class-option-panel .detail-container {
        border-radius: 0 1rem 1rem 0; } }
    @media screen and (max-width: 990px) {
      .service-class-option-panel .detail-container {
        border-radius: 0 0 1rem 1rem; } }
    .service-class-option-panel .detail-container .header-row {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr; }
      @media screen and (max-width: 990px) {
        .service-class-option-panel .detail-container .header-row {
          grid-template-columns: 1fr 1fr;
          gap: 1rem; } }
      .service-class-option-panel .detail-container .header-row .labels {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        .service-class-option-panel .detail-container .header-row .labels .name {
          font-size: 2rem;
          font-weight: bold; }
        .service-class-option-panel .detail-container .header-row .labels .subtitle {
          font-size: 1.125rem; }
      .service-class-option-panel .detail-container .header-row .detail-icon-row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem; }
        @media screen and (max-width: 990px) {
          .service-class-option-panel .detail-container .header-row .detail-icon-row {
            justify-content: flex-end;
            align-items: flex-start; } }
        .service-class-option-panel .detail-container .header-row .detail-icon-row .detail-icon-container {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .service-class-option-panel .detail-container .header-row .detail-icon-row .detail-icon-container .icon {
            height: 1.75rem;
            width: auto; }
          .service-class-option-panel .detail-container .header-row .detail-icon-row .detail-icon-container .text {
            font-size: 1.125rem; }
      .service-class-option-panel .detail-container .header-row .price {
        align-items: flex-end; }
        @media screen and (max-width: 990px) {
          .service-class-option-panel .detail-container .header-row .price {
            grid-column: span 2;
            align-items: flex-start; } }
    .service-class-option-panel .detail-container .bulletpoints-list {
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: .5rem; }
      .service-class-option-panel .detail-container .bulletpoints-list .bulletpoint-container {
        display: flex;
        align-items: center;
        gap: .5rem;
        font-size: 1.125rem; }
        .service-class-option-panel .detail-container .bulletpoints-list .bulletpoint-container .square {
          min-height: 1.5rem;
          min-width: 1.5rem;
          background-color: #579485; }
    .service-class-option-panel .detail-container .bottom-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem; }
      .service-class-option-panel .detail-container .bottom-row .cancelation-policy-container {
        grid-column: span 2;
        align-self: end; }
        @media screen and (max-width: 990px) {
          .service-class-option-panel .detail-container .bottom-row .cancelation-policy-container {
            grid-column: span 3; } }
        .service-class-option-panel .detail-container .bottom-row .cancelation-policy-container .title {
          margin-bottom: .5rem; }
      .service-class-option-panel .detail-container .bottom-row .booking-button {
        align-self: end;
        width: 100%;
        height: 3rem;
        padding: 1rem 0;
        border-radius: 1rem;
        color: white;
        text-align: center;
        cursor: pointer;
        background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
        transition: background .2s ease;
        user-select: none; }
        @media screen and (max-width: 990px) {
          .service-class-option-panel .detail-container .bottom-row .booking-button {
            grid-column: span 3; } }
        .service-class-option-panel .detail-container .bottom-row .booking-button:active {
          background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }

.styled-hour-picker {
  width: 100%; }
  .styled-hour-picker .label {
    padding: .5rem 0; }

.styled-select {
  width: 100%; }
  .styled-select .label {
    padding: .5rem 0; }
  .styled-select .text-input {
    height: 3rem;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    color: #696969;
    background-color: #a6c2bb;
    resize: none; }

.ride {
  width: 100%;
  background-color: #f5f5f5;
  padding: 1rem 2rem;
  border-radius: 1rem; }
  @media screen and (max-width: 990px) {
    .ride {
      padding: 1rem; } }
  .ride .fullscreen-container {
    position: fixed;
    z-index: 10;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .ride .fullscreen-container .cancel-box {
      margin-top: 10rem;
      width: 90%;
      max-width: 60rem;
      background-color: white;
      border-radius: 1rem; }
      .ride .fullscreen-container .cancel-box .review-header {
        border-radius: 1rem 1rem 0 0;
        padding: 1rem;
        background-color: #579485;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .ride .fullscreen-container .cancel-box .review-header .text {
          color: white; }
        .ride .fullscreen-container .cancel-box .review-header .close-button {
          height: 2rem;
          width: auto;
          filter: invert(1);
          cursor: pointer; }
      .ride .fullscreen-container .cancel-box .review-body {
        padding: 1rem;
        display: flex;
        gap: 1rem; }
        .ride .fullscreen-container .cancel-box .review-body .button {
          padding: .5rem 1rem;
          background-color: #e6e6e6;
          border-radius: .2rem;
          cursor: pointer; }
        .ride .fullscreen-container .cancel-box .review-body .confirm {
          background-color: #579485;
          color: white; }
    .ride .fullscreen-container .review-box {
      margin-top: 10rem;
      width: 90%;
      max-width: 60rem;
      background-color: white;
      border-radius: 1rem; }
      .ride .fullscreen-container .review-box .review-header {
        border-radius: 1rem 1rem 0 0;
        padding: 1rem;
        background-color: #579485;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .ride .fullscreen-container .review-box .review-header .text {
          color: white; }
        .ride .fullscreen-container .review-box .review-header .close-button {
          height: 2rem;
          width: auto;
          filter: invert(1);
          cursor: pointer; }
      .ride .fullscreen-container .review-box .review-body {
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr); }
        .ride .fullscreen-container .review-box .review-body .info-cell .cell-header {
          color: #555555; }
        .ride .fullscreen-container .review-box .review-body .review-span2-container {
          margin-top: 1rem;
          grid-column: span 2; }
          .ride .fullscreen-container .review-box .review-body .review-span2-container .label {
            margin-bottom: .5rem; }
          .ride .fullscreen-container .review-box .review-body .review-span2-container textarea {
            width: 100%;
            height: 4.8rem;
            resize: none;
            padding: .2rem;
            border-radius: .2rem;
            border: none;
            background-color: #dfdfdf; }
            .ride .fullscreen-container .review-box .review-body .review-span2-container textarea:focus {
              outline: none; }
          .ride .fullscreen-container .review-box .review-body .review-span2-container .star-container {
            display: flex;
            align-items: center; }
            .ride .fullscreen-container .review-box .review-body .review-span2-container .star-container .star {
              padding-right: .5rem;
              content: "\2605";
              cursor: pointer; }
      .ride .fullscreen-container .review-box .review-footer {
        border-radius: 0 0 1rem 1rem;
        padding: 1rem;
        background-color: black;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        align-items: center; }
        .ride .fullscreen-container .review-box .review-footer .button {
          color: white;
          background-color: #6C757D;
          padding: .5rem 1rem;
          border-radius: .2rem;
          cursor: pointer; }
        .ride .fullscreen-container .review-box .review-footer .submit {
          background-color: #579485; }
  .ride .content-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem; }
    @media screen and (max-width: 1700px) {
      .ride .content-container {
        grid-template-columns: repeat(4, 1fr); } }
    @media screen and (max-width: 990px) {
      .ride .content-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem; } }
    .ride .content-container .cell {
      width: 100%;
      /* Chrome, Safari, Edge, Opera */
      /* Firefox */ }
      @media screen and (max-width: 990px) {
        .ride .content-container .cell {
          width: calc(50% - 1rem); } }
      .ride .content-container .cell .label {
        padding: .5rem 0;
        color: #121212; }
      .ride .content-container .cell .text {
        width: 100%;
        border-radius: 1rem;
        outline: none;
        border: 1px solid #555555;
        padding: 1rem;
        color: #555555;
        background-color: #f5f5f5; }
      .ride .content-container .cell input::-webkit-outer-spin-button,
      .ride .content-container .cell input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .ride .content-container .cell input[type=number] {
        -moz-appearance: textfield; }
    .ride .content-container .tablet-fillerspace {
      display: none; }
      @media screen and (max-width: 1700px) {
        .ride .content-container .tablet-fillerspace {
          display: block; } }
    @media screen and (max-width: 1700px) {
      .ride .content-container .noTablet {
        display: none; } }
    .ride .content-container .big {
      grid-column: span 2; }
      @media screen and (max-width: 990px) {
        .ride .content-container .big {
          grid-column: span 1; } }
    .ride .content-container .history-details-button, .ride .content-container .history-details-button-disabled {
      margin-top: 2.2rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      padding: 1rem 0;
      background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
      border-radius: 1rem;
      cursor: pointer; }
      .ride .content-container .history-details-button:active, .ride .content-container .history-details-button-disabled:active {
        background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
    .ride .content-container .history-details-button-disabled {
      cursor: default;
      color: #e4e4e4;
      background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
    .ride .content-container .text-container {
      grid-column: span 4;
      text-align: center; }
    .ride .content-container .button-container {
      grid-column: span 2;
      display: flex;
      justify-content: space-between;
      margin-top: 2.2rem;
      align-items: center; }
      @media screen and (max-width: 990px) {
        .ride .content-container .button-container {
          width: 100%; } }
      .ride .content-container .button-container .button {
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        padding: 1rem 0;
        background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
        border-radius: 1rem;
        cursor: pointer; }
        .ride .content-container .button-container .button:active {
          background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
      .ride .content-container .button-container .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5rem;
        background-color: white;
        height: 3.2rem;
        width: 3.2rem;
        cursor: pointer;
        user-select: none; }
        .ride .content-container .button-container .icon-container:active {
          background-color: whitesmoke; }
        .ride .content-container .button-container .icon-container .icon {
          height: 40%;
          width: auto;
          transform: rotate(180deg); }

.sidebar-container {
  width: 70%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.2s;
  z-index: 50; }
  .sidebar-container .close-button-container {
    padding: 2.75rem 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .sidebar-container .close-button-container .logo {
      filter: brightness(0) saturate(100%);
      width: 50%;
      height: auto; }
    .sidebar-container .close-button-container .close-button {
      width: 1.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .sidebar-container .close-button-container .close-button .icon {
        height: 100%; }
  .sidebar-container .link-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: calc(100vh - 7.2rem);
    overflow: scroll; }
    .sidebar-container .link-container .link {
      padding: 2rem 1rem;
      text-decoration: none;
      color: black; }
    .sidebar-container .link-container .active {
      background-color: #579485;
      color: white; }

@media (max-width: 990px) {
  .sidebar-open {
    transform: translateX(0); } }

.popup-bounds {
  z-index: 15;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0; }

.popup {
  top: 2rem;
  position: absolute;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 2rem;
  border-radius: 1rem;
  transition: all .5s ease;
  transform: translateY(calc(-100% - 2rem)); }
  @media screen and (max-width: 990px) {
    .popup {
      width: 90%; } }
  .popup .text {
    font-size: 1.5rem; }

.home .form-section {
  padding: 2rem 10rem;
  position: relative;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  display: flex;
  align-items: center;
  margin-bottom: 2rem; }
  @media screen and (max-width: 1700px) {
    .home .form-section {
      padding: 2rem 5rem; } }
  @media screen and (max-width: 990px) {
    .home .form-section {
      padding: 2rem 1rem;
      flex-direction: column; } }
  .home .form-section .section-label {
    font-size: 1.75rem;
    color: white;
    left: -3rem;
    position: absolute;
    transform: rotate(-90deg); }
    @media screen and (max-width: 990px) {
      .home .form-section .section-label {
        transform: rotate(0deg);
        position: relative;
        left: 0;
        margin-bottom: 2.2rem; } }
  .home .form-section .image-carrousel-container {
    width: calc((100%/3) * 2);
    height: 22rem; }
    @media screen and (max-width: 1700px) {
      .home .form-section .image-carrousel-container {
        width: 35%; } }
    @media screen and (max-width: 990px) {
      .home .form-section .image-carrousel-container {
        width: 100%;
        display: none; } }
  .home .form-section .form-container {
    padding: 0 1rem 0 2rem;
    width: calc(100%/3); }
    @media screen and (max-width: 1700px) {
      .home .form-section .form-container {
        padding: 0 1rem 0 1rem;
        width: 65%; } }
    @media screen and (max-width: 990px) {
      .home .form-section .form-container {
        width: 100%;
        margin-bottom: 2rem; } }

.home .animated-carrousel-container {
  width: 100%;
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .home .animated-carrousel-container {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .home .animated-carrousel-container {
      padding: 0 1rem; } }

.home .booking-row-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10rem;
  margin: 0 0 5rem; }
  @media screen and (max-width: 1700px) {
    .home .booking-row-section {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .home .booking-row-section {
      padding: 0 1rem; } }
  .home .booking-row-section .booking-row {
    width: calc(100%/3 - 2rem);
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem; }
    @media screen and (max-width: 1700px) {
      .home .booking-row-section .booking-row {
        flex-direction: column;
        gap: 1rem;
        height: 7rem; } }
    @media screen and (max-width: 990px) {
      .home .booking-row-section .booking-row {
        width: 100%; } }
    .home .booking-row-section .booking-row .flip-container {
      width: 50%;
      height: 3rem; }
      @media screen and (max-width: 1700px) {
        .home .booking-row-section .booking-row .flip-container {
          width: 100%; } }
      .home .booking-row-section .booking-row .flip-container .option-capsule {
        padding: 1rem; }
    .home .booking-row-section .booking-row .booking-button {
      width: 50%;
      height: 3rem;
      padding: 1rem 0;
      border-radius: 1rem;
      color: white;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
      transition: background .2s ease;
      user-select: none; }
      @media screen and (max-width: 1700px) {
        .home .booking-row-section .booking-row .booking-button {
          width: 100%; } }
      .home .booking-row-section .booking-row .booking-button:active {
        background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }

.home .phone-section {
  padding: 0 10rem;
  display: flex;
  margin-bottom: 5rem; }
  @media screen and (max-width: 1700px) {
    .home .phone-section {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .home .phone-section {
      padding: 0 1rem;
      flex-direction: column;
      align-items: center;
      gap: 2rem; } }
  .home .phone-section .panel {
    width: calc(100% / 3);
    display: grid;
    padding: 0 1rem; }
    @media screen and (max-width: 990px) {
      .home .phone-section .panel {
        width: 70%; } }
    .home .phone-section .panel .panel-image {
      margin: auto;
      margin-bottom: 1rem;
      width: auto;
      height: 20rem; }
    .home .phone-section .panel .title {
      margin: auto;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: .5rem; }
    .home .phone-section .panel .text {
      margin: auto;
      width: 90%;
      text-align: center;
      margin-bottom: 1rem; }
    .home .phone-section .panel .booking-button {
      width: 100%;
      height: 3rem;
      padding: 1rem 0;
      border-radius: 1rem;
      color: white;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
      transition: background .2s ease;
      user-select: none; }
      .home .phone-section .panel .booking-button:active {
        background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
  .home .phone-section .first {
    padding: 0 1rem 0 0; }
    @media screen and (max-width: 990px) {
      .home .phone-section .first {
        padding: 0; } }
  .home .phone-section .last {
    padding: 0 0 0 1rem; }
    @media screen and (max-width: 990px) {
      .home .phone-section .last {
        padding: 0; } }

.home .banner-section {
  background-color: #ebebeb;
  padding: 3rem 0;
  margin-bottom: 5rem; }
  .home .banner-section .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem; }
    @media screen and (max-width: 1700px) {
      .home .banner-section .banner-content {
        padding: 0 5rem; } }
    @media screen and (max-width: 990px) {
      .home .banner-section .banner-content {
        padding: 0 1rem;
        flex-direction: column-reverse; } }
    .home .banner-section .banner-content .text {
      font-size: 1rem;
      /* Adjust the font size as needed */
      font-weight: bold;
      width: 50%; }
      @media screen and (max-width: 990px) {
        .home .banner-section .banner-content .text {
          width: 90%; } }
    .home .banner-section .banner-content .images {
      display: flex; }
      .home .banner-section .banner-content .images .image-container {
        height: auto;
        position: relative; }
        .home .banner-section .banner-content .images .image-container .image {
          right: 0;
          position: absolute;
          transform: translateY(-50%);
          height: 8rem;
          width: auto; }
          @media screen and (max-width: 990px) {
            .home .banner-section .banner-content .images .image-container .image {
              position: relative !important;
              transform: translateY(0%) !important;
              width: 100%;
              height: auto; } }
        @media screen and (max-width: 990px) {
          .home .banner-section .banner-content .images .image-container .leftCar {
            transform: translateX(20%) !important; } }
        @media screen and (max-width: 990px) {
          .home .banner-section .banner-content .images .image-container .rightCar {
            transform: translateX(-20%) !important; } }

.home .text-grid-section {
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 3rem 10rem;
  margin-bottom: 5rem; }
  @media screen and (max-width: 1700px) {
    .home .text-grid-section {
      padding: 3rem 5rem; } }
  @media screen and (max-width: 990px) {
    .home .text-grid-section {
      padding: 3rem 1rem; } }
  .home .text-grid-section .text-grid {
    padding: 0 10rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem 2rem; }
    @media screen and (max-width: 1700px) {
      .home .text-grid-section .text-grid {
        padding: 0 5rem;
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 990px) {
      .home .text-grid-section .text-grid {
        padding: 0 1rem;
        grid-template-columns: repeat(1, 1fr); } }
    .home .text-grid-section .text-grid .grid-item {
      color: white;
      text-align: center; }
      .home .text-grid-section .text-grid .grid-item .title {
        font-size: 2rem;
        margin-bottom: 1rem; }

.home .download-section {
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .home .download-section {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .home .download-section {
      padding: 0 1rem; } }
  .home .download-section .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: .5rem; }
  .home .download-section .text {
    width: 30%;
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 1700px) {
      .home .download-section .text {
        width: 50%; } }
    @media screen and (max-width: 990px) {
      .home .download-section .text {
        width: 70%; } }
  .home .download-section .download-button-row {
    display: flex;
    gap: 2rem; }
    .home .download-section .download-button-row .download-button {
      cursor: pointer; }
      @media screen and (max-width: 990px) {
        .home .download-section .download-button-row .download-button {
          width: 10rem; } }

.about-us .page-header {
  font-size: 2.5rem;
  color: white;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 3rem; }

.about-us .content {
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .about-us .content {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .about-us .content {
      padding: 0 1rem; } }
  .about-us .content .top-text {
    text-align: center;
    margin-bottom: 3rem; }
  .about-us .content .panel-carrousel-section {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center; }
    .about-us .content .panel-carrousel-section .panel-carrousel-container {
      width: 90%; }
  .about-us .content .text-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 3rem; }
    @media screen and (max-width: 1700px) {
      .about-us .content .text-grid {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 990px) {
      .about-us .content .text-grid {
        grid-template-columns: repeat(1, 1fr); } }
    .about-us .content .text-grid .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
      border-radius: 1rem;
      color: #ffffff;
      padding: 1rem; }
      .about-us .content .text-grid .text-container .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1rem; }
  .about-us .content .video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 3rem; }
    @media screen and (max-width: 1700px) {
      .about-us .content .video-grid {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 990px) {
      .about-us .content .video-grid {
        grid-template-columns: repeat(1, 1fr); } }
    .about-us .content .video-grid .video-container {
      min-height: 15rem;
      border-radius: 1rem;
      width: 100%;
      background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
      cursor: pointer; }

.contact {
  min-height: calc(100vh - 6rem - 8rem); }
  .contact .page-header {
    font-size: 2.5rem;
    color: white;
    background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 3rem; }
  .contact .content {
    padding: 0 10rem; }
    @media screen and (max-width: 1700px) {
      .contact .content {
        padding: 0 5rem; } }
    @media screen and (max-width: 990px) {
      .contact .content {
        padding: 0 1rem; } }
    .contact .content .detail-row {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      margin-bottom: 1rem; }
      @media screen and (max-width: 1700px) {
        .contact .content .detail-row {
          display: grid;
          grid-template-columns: repeat(2, 1fr); } }
      @media screen and (max-width: 990px) {
        .contact .content .detail-row {
          display: grid;
          grid-template-columns: 1fr; } }
      .contact .content .detail-row .detail-container {
        background-color: #ebebeb;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 1rem;
        padding: 2rem; }
        @media screen and (max-width: 990px) {
          .contact .content .detail-row .detail-container {
            margin: auto; } }
        .contact .content .detail-row .detail-container .title {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 1rem; }
        .contact .content .detail-row .detail-container .link-container {
          display: flex;
          flex-wrap: wrap;
          gap: .5rem 1rem; }
          .contact .content .detail-row .detail-container .link-container .link {
            display: flex;
            align-items: center;
            width: calc(50% - .5rem);
            text-decoration: none;
            color: black;
            cursor: pointer; }
            @media screen and (max-width: 990px) {
              .contact .content .detail-row .detail-container .link-container .link {
                width: 100%; } }
            .contact .content .detail-row .detail-container .link-container .link .square {
              height: 1rem;
              min-width: 1rem;
              margin-right: .2rem;
              background-color: #579485; }
    .contact .content .bottom-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
      margin-bottom: 3rem; }
      @media screen and (max-width: 1700px) {
        .contact .content .bottom-row {
          grid-template-columns: 1fr 1fr; } }
      @media screen and (max-width: 990px) {
        .contact .content .bottom-row {
          display: flex;
          flex-direction: column-reverse; } }
      .contact .content .bottom-row .map {
        width: 100%;
        border-radius: 1rem;
        grid-column: span 2;
        margin-top: 2.2rem;
        height: calc(100% - 2.2rem);
        background-color: #f5f5f5; }
        @media screen and (max-width: 1700px) {
          .contact .content .bottom-row .map {
            grid-column: span 1; } }
        @media screen and (max-width: 990px) {
          .contact .content .bottom-row .map {
            display: flex;
            flex-direction: column-reverse;
            min-height: 30rem; } }
      .contact .content .bottom-row .form {
        width: 100%; }
        .contact .content .bottom-row .form .captcha-container {
          margin-top: 2.2rem;
          width: 100%;
          display: flex;
          justify-content: center; }
        .contact .content .bottom-row .form .submit-button {
          margin-top: 2.2rem;
          width: 100%;
          height: 3rem;
          padding: 1rem 0;
          border-radius: 1rem;
          color: white;
          text-align: center;
          cursor: pointer;
          background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
          transition: background .2s ease;
          user-select: none; }
          .contact .content .bottom-row .form .submit-button:active {
            background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }

.career {
  min-height: calc(100vh - 6rem - 8rem); }
  .career .page-header {
    padding: 0 10rem;
    font-size: 2.5rem;
    color: white;
    background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 3rem; }
  .career .content {
    padding: 0 10rem 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem; }
    @media screen and (max-width: 1700px) {
      .career .content {
        padding: 0 5rem 3rem;
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 990px) {
      .career .content {
        padding: 0 1rem 3rem;
        display: flex;
        flex-direction: column; } }
    .career .content .text-container {
      width: 100%;
      grid-column: span 2; }
      @media screen and (max-width: 1700px) {
        .career .content .text-container {
          grid-column: span 1; } }
      .career .content .text-container .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1rem; }
      .career .content .text-container .text {
        margin-bottom: 1rem; }
      .career .content .text-container .list-container {
        display: flex;
        flex-direction: column;
        gap: 1rem; }
        .career .content .text-container .list-container .list-element {
          display: flex;
          align-items: center; }
        .career .content .text-container .list-container .square {
          min-width: 1rem;
          min-height: 1rem;
          background-color: #579485;
          margin-right: 1rem; }
        .career .content .text-container .list-container .text {
          margin-bottom: 0; }
    .career .content .form {
      width: 100%; }
      .career .content .form .checkbox-container {
        margin-top: 1rem; }
        .career .content .form .checkbox-container .star {
          color: red; }
      .career .content .form .button-row {
        display: flex;
        justify-content: space-between;
        gap: 2rem; }
      .career .content .form .attach-button {
        margin-top: 2.2rem;
        width: 50%;
        height: 3rem;
        padding: 1rem 0;
        border-radius: 1rem;
        color: #555555;
        text-align: center;
        cursor: pointer;
        background: #f5f5f5;
        user-select: none; }
      .career .content .form .submit-button {
        margin-top: 2.2rem;
        width: 50%;
        height: 3rem;
        padding: 1rem 0;
        border-radius: 1rem;
        color: white;
        text-align: center;
        cursor: pointer;
        background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
        transition: background .2s ease;
        user-select: none; }
        .career .content .form .submit-button:active {
          background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }

.service-class {
  min-height: calc(100vh - 6rem - 8rem);
  position: relative; }
  .service-class .no-user-overlay {
    z-index: 20;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    padding-top: calc(50vh - 4rem);
    gap: 1rem; }
    .service-class .no-user-overlay .text {
      color: white;
      text-align: center; }
    .service-class .no-user-overlay .button {
      color: white;
      border-radius: 1rem;
      padding: .5rem 1rem;
      background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
      text-decoration: none; }
      .service-class .no-user-overlay .button:active {
        background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
  .service-class .page-header {
    font-size: 2.5rem;
    color: white;
    background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 3rem; }
    @media screen and (max-width: 990px) {
      .service-class .page-header {
        margin-bottom: 0; } }
  .service-class .content {
    padding: 2rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 1700px) {
      .service-class .content {
        padding: 2rem 5rem; } }
    @media screen and (max-width: 990px) {
      .service-class .content {
        padding: 2rem 1rem; } }
    .service-class .content .class-select-view-1-container, .service-class .content .class-select-view-2-container {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .service-class .content .step-row {
      display: flex;
      gap: 2rem;
      align-items: flex-start; }
      @media screen and (max-width: 990px) {
        .service-class .content .step-row {
          width: 100vw;
          gap: 1rem;
          padding: 0 1rem;
          align-items: center;
          overflow-x: scroll; } }
      .service-class .content .step-row .step-container {
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 990px) {
          .service-class .content .step-row .step-container {
            width: 100%;
            align-self: flex-start; } }
        .service-class .content .step-row .step-container .icon-container {
          width: 2.5rem;
          height: 2.5rem;
          margin-bottom: 1rem; }
        .service-class .content .step-row .step-container .step-title {
          text-align: center; }
      .service-class .content .step-row .chevron-right {
        transform: rotate(90deg);
        height: 1rem;
        margin: 1rem 0;
        filter: invert(44%) sepia(1%) saturate(0%) hue-rotate(161deg) brightness(98%) contrast(93%); }
        @media screen and (max-width: 990px) {
          .service-class .content .step-row .chevron-right {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0; } }
    .service-class .content .class-select-view-1-container {
      width: 100%; }
      .service-class .content .class-select-view-1-container .booking-row-section {
        width: calc(100%/3 - 2rem);
        display: flex;
        justify-content: center;
        margin: 0 0 5rem; }
        @media screen and (max-width: 1700px) {
          .service-class .content .class-select-view-1-container .booking-row-section {
            margin: 0 0 7rem; } }
        @media screen and (max-width: 990px) {
          .service-class .content .class-select-view-1-container .booking-row-section {
            width: 100%; } }
        .service-class .content .class-select-view-1-container .booking-row-section .booking-row {
          width: 100%;
          height: 3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem; }
          @media screen and (max-width: 1700px) {
            .service-class .content .class-select-view-1-container .booking-row-section .booking-row {
              flex-direction: column;
              gap: 1rem; } }
          .service-class .content .class-select-view-1-container .booking-row-section .booking-row .flip-container {
            width: 50%;
            height: 3rem; }
            @media screen and (max-width: 1700px) {
              .service-class .content .class-select-view-1-container .booking-row-section .booking-row .flip-container {
                width: 100%; } }
            .service-class .content .class-select-view-1-container .booking-row-section .booking-row .flip-container .option-capsule {
              padding: 1rem; }
          .service-class .content .class-select-view-1-container .booking-row-section .booking-row .booking-button {
            width: 50%;
            height: 3rem;
            padding: 1rem 0;
            border-radius: 1rem;
            color: white;
            text-align: center;
            cursor: pointer;
            background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
            transition: background .2s ease;
            user-select: none; }
            @media screen and (max-width: 1700px) {
              .service-class .content .class-select-view-1-container .booking-row-section .booking-row .booking-button {
                width: 100%; } }
            .service-class .content .class-select-view-1-container .booking-row-section .booking-row .booking-button:active {
              background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
      .service-class .content .class-select-view-1-container .service-class-options-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3rem; }
        .service-class .content .class-select-view-1-container .service-class-options-list .loading-container {
          width: 100%;
          height: 5rem; }
        .service-class .content .class-select-view-1-container .service-class-options-list .service-class-options-container {
          width: 100%; }
    .service-class .content .class-select-view-2 {
      width: 30%;
      min-width: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media screen and (max-width: 990px) {
        .service-class .content .class-select-view-2 {
          width: 100%;
          padding: 0 1rem;
          min-width: unset; } }
      .service-class .content .class-select-view-2 .header {
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem 0; }
      .service-class .content .class-select-view-2 .view-container {
        width: 100%; }
        .service-class .content .class-select-view-2 .view-container .class-select-button-container .label {
          padding: .5rem 0; }
        .service-class .content .class-select-view-2 .view-container .class-select-button-container .button {
          height: 3rem;
          width: 100%;
          padding: 1rem 2rem;
          border-radius: 1rem;
          color: #555555;
          background-color: #f5f5f5;
          resize: none;
          cursor: pointer;
          margin-bottom: 1rem; }
        .service-class .content .class-select-view-2 .view-container .option-details {
          width: 100%; }
          .service-class .content .class-select-view-2 .view-container .option-details .header-row {
            margin-bottom: 1rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .service-class .content .class-select-view-2 .view-container .option-details .header-row .labels .name {
              font-size: 2.5rem;
              font-weight: bold; }
            .service-class .content .class-select-view-2 .view-container .option-details .header-row .labels .subtitle {
              font-size: 1.125rem; }
            .service-class .content .class-select-view-2 .view-container .option-details .header-row .icons {
              display: flex;
              gap: 1rem; }
              .service-class .content .class-select-view-2 .view-container .option-details .header-row .icons .detail-icon {
                display: flex;
                flex-direction: column;
                align-items: center; }
                .service-class .content .class-select-view-2 .view-container .option-details .header-row .icons .detail-icon .icon {
                  height: 1.75rem;
                  width: auto; }
                .service-class .content .class-select-view-2 .view-container .option-details .header-row .icons .detail-icon .text {
                  font-size: 1.125rem; }
          .service-class .content .class-select-view-2 .view-container .option-details .car-image-container {
            margin-bottom: 1rem;
            width: 100%;
            height: 12rem;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center; }
            .service-class .content .class-select-view-2 .view-container .option-details .car-image-container .car {
              width: auto;
              height: 100%;
              z-index: 5; }
              @media screen and (max-width: 990px) {
                .service-class .content .class-select-view-2 .view-container .option-details .car-image-container .car {
                  width: 80%;
                  height: auto; } }
            .service-class .content .class-select-view-2 .view-container .option-details .car-image-container .square {
              position: absolute;
              width: 100%;
              height: 80%;
              bottom: 0;
              background-color: #ebebeb;
              border-radius: 1rem; }
          .service-class .content .class-select-view-2 .view-container .option-details .cancelation-container {
            font-size: 1.125rem; }
            .service-class .content .class-select-view-2 .view-container .option-details .cancelation-container .bulletpoint {
              margin: 1rem 0; }
          .service-class .content .class-select-view-2 .view-container .option-details .select-button {
            margin-bottom: 3rem;
            width: 100%;
            height: 3rem;
            padding: 1rem 0;
            border-radius: 1rem;
            color: white;
            text-align: center;
            cursor: pointer;
            background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
            transition: background .2s ease;
            user-select: none; }
            .service-class .content .class-select-view-2 .view-container .option-details .select-button:active {
              background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
    .service-class .content .details-view {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .service-class .content .details-view .header {
        padding: 2.75rem 0 0;
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem 0; }
      .service-class .content .details-view .text {
        width: 50%;
        text-align: center;
        margin-bottom: 2rem; }
        @media screen and (max-width: 990px) {
          .service-class .content .details-view .text {
            width: 80%; } }
      .service-class .content .details-view .form-container {
        width: 30%;
        min-width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 990px) {
          .service-class .content .details-view .form-container {
            width: 100%;
            padding: 0 1rem;
            min-width: unset; } }
        .service-class .content .details-view .form-container .text-input-container {
          position: relative;
          width: 100%; }
          .service-class .content .details-view .form-container .text-input-container .predictions-container {
            z-index: 20;
            padding: 1rem 1rem;
            width: 100%;
            background-color: white;
            position: absolute;
            border-radius: 0 0 1rem 1rem;
            color: #696969;
            display: flex;
            flex-direction: column;
            gap: .5rem; }
            .service-class .content .details-view .form-container .text-input-container .predictions-container .prediction {
              cursor: pointer; }
        .service-class .content .details-view .form-container .switch-container {
          width: 100%; }
        .service-class .content .details-view .form-container .row {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem; }
          .service-class .content .details-view .form-container .row .input-container {
            width: 100%; }
        .service-class .content .details-view .form-container .button {
          margin-top: 2.2rem;
          width: 100%;
          height: 3rem;
          padding: 1rem 0;
          border-radius: 1rem;
          color: white;
          text-align: center;
          cursor: pointer;
          background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
          transition: background .2s ease;
          user-select: none; }
          .service-class .content .details-view .form-container .button:active {
            background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
    .service-class .content .options-view {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .service-class .content .options-view .header {
        padding: 2.75rem 0 0;
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem 0; }
      .service-class .content .options-view .form-container {
        width: 30%;
        min-width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 990px) {
          .service-class .content .options-view .form-container {
            width: 100%;
            padding: 0 1rem;
            min-width: unset; } }
        .service-class .content .options-view .form-container .text {
          color: #555555;
          font-size: 0.75rem; }
        .service-class .content .options-view .form-container .checkbox-container {
          margin: 2rem 0; }
        .service-class .content .options-view .form-container .optional-container {
          width: 100%;
          margin-bottom: 2rem; }
          .service-class .content .options-view .form-container .optional-container .text {
            font-size: 0.75rem;
            color: #555555; }
        .service-class .content .options-view .form-container .switch-buttons {
          margin-top: 1.2rem;
          display: grid;
          width: 100%;
          border-radius: 0 1rem 1rem 0;
          grid-template-columns: repeat(2, 1fr);
          color: white;
          cursor: pointer; }
          .service-class .content .options-view .form-container .switch-buttons .left {
            border: 1px solid #579485;
            background-color: #579485;
            border-radius: 1rem 0 0 1rem; }
          .service-class .content .options-view .form-container .switch-buttons .right {
            border: 1px solid #579485;
            border-radius: 0 1rem 1rem 0;
            color: #579485; }
          .service-class .content .options-view .form-container .switch-buttons .switch-button {
            padding: 1rem 0;
            width: 100%;
            align-self: center;
            text-align: center; }
    .service-class .content .payment-view {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .service-class .content .payment-view .header {
        padding: 2.75rem 0 0;
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem 0; }
      .service-class .content .payment-view .input-container {
        width: 30%;
        min-width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 990px) {
          .service-class .content .payment-view .input-container {
            width: 100%;
            padding: 0 1rem;
            min-width: unset; } }
        .service-class .content .payment-view .input-container .expiration-date-label {
          width: 100%;
          padding: .5rem 0; }
        .service-class .content .payment-view .input-container .expiration-date-input-row {
          display: grid;
          gap: 1rem;
          grid-template-columns: repeat(3, 1fr);
          margin-bottom: 2rem;
          /* Hide the default number input arrows */
          /* Disable the increment and decrement arrows */ }
          .service-class .content .payment-view .input-container .expiration-date-input-row .expiration-date-input {
            height: 3rem;
            width: 100%;
            padding: 1rem 1rem;
            border-radius: 1rem;
            border: none;
            outline: none;
            color: #696969;
            background-color: #f5f5f5;
            resize: none; }
            .service-class .content .payment-view .input-container .expiration-date-input-row .expiration-date-input:focus {
              outline: none; }
          .service-class .content .payment-view .input-container .expiration-date-input-row input[type="number"]::-webkit-inner-spin-button,
          .service-class .content .payment-view .input-container .expiration-date-input-row input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            appearance: none;
            margin: 0; }
          .service-class .content .payment-view .input-container .expiration-date-input-row input[type="number"] {
            appearance: textfield;
            -moz-appearance: textfield;
            /* Firefox */ }
        .service-class .content .payment-view .input-container .switch-buttons {
          display: grid;
          width: 100%;
          border-radius: 0 1rem 1rem 0;
          grid-template-columns: repeat(2, 1fr);
          color: white;
          cursor: pointer;
          margin-bottom: 4rem; }
          .service-class .content .payment-view .input-container .switch-buttons .left {
            border: 1px solid #579485;
            background-color: #579485;
            border-radius: 1rem 0 0 1rem; }
          .service-class .content .payment-view .input-container .switch-buttons .right {
            border: 1px solid #579485;
            border-radius: 0 1rem 1rem 0;
            color: #579485; }
          .service-class .content .payment-view .input-container .switch-buttons .switch-button {
            padding: 1rem 0;
            width: 100%;
            align-self: center;
            text-align: center; }
        .service-class .content .payment-view .input-container .disclaimer {
          font-size: 0.75rem;
          text-align: center; }
    .service-class .content .checkout-view {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .service-class .content .checkout-view *:read-only {
        cursor: default; }
      .service-class .content .checkout-view .header {
        padding: 2.75rem 0 0;
        font-size: 2rem;
        font-weight: bold;
        margin: 1rem 0; }
      .service-class .content .checkout-view .checkout-content {
        width: 30%;
        min-width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media screen and (max-width: 990px) {
          .service-class .content .checkout-view .checkout-content {
            width: 100%;
            padding: 0 1rem;
            min-width: unset; } }
        .service-class .content .checkout-view .checkout-content .icon-container {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ebebeb;
          margin-bottom: 1rem; }
          .service-class .content .checkout-view .checkout-content .icon-container .icon {
            height: 50%;
            width: 50%;
            filter: brightness(0) saturate(100%) invert(46%) sepia(20%) saturate(0%) hue-rotate(185deg) brightness(90%) contrast(84%); }
        .service-class .content .checkout-view .checkout-content .text {
          text-align: center;
          margin-bottom: 1rem; }
        .service-class .content .checkout-view .checkout-content .row {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem; }
          .service-class .content .checkout-view .checkout-content .row .input-container {
            width: 100%; }
        .service-class .content .checkout-view .checkout-content .switch-buttons {
          display: grid;
          width: 100%;
          border-radius: 0 1rem 1rem 0;
          grid-template-columns: repeat(2, 1fr);
          color: white;
          margin-bottom: 4rem; }
          .service-class .content .checkout-view .checkout-content .switch-buttons .left {
            border: 1px solid #579485;
            background-color: #579485;
            border-radius: 1rem 0 0 1rem;
            cursor: pointer; }
          .service-class .content .checkout-view .checkout-content .switch-buttons .right {
            border: 1px solid #579485;
            border-radius: 0 1rem 1rem 0;
            color: #579485;
            text-decoration: none; }
          .service-class .content .checkout-view .checkout-content .switch-buttons .switch-button {
            margin-top: 2.2rem;
            padding: 1rem 0;
            width: 100%;
            align-self: center;
            text-align: center;
            user-select: none;
            cursor: pointer; }
            .service-class .content .checkout-view .checkout-content .switch-buttons .switch-button .loading-container {
              height: calc(1rem + 2px); }

.login {
  min-height: calc(100vh - 6rem - 8rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 5rem; }
  .login .page-header {
    width: 100%;
    font-size: 2.5rem;
    color: white;
    background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 6rem; }
  .login .header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: .5rem;
    padding: 0; }
  .login .text {
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 2rem; }
    @media screen and (max-width: 990px) {
      .login .text {
        width: 100%;
        padding: 0 1rem; } }
    .login .text .link-with-background {
      text-decoration: underline;
      color: #3665f3;
      transition: background-color 0.2s;
      padding: 2px 6px;
      border-radius: 4px;
      display: inline-block; }
      .login .text .link-with-background:hover {
        background-color: #ebebeb;
        /* Change to the desired background color on hover */
        color: #3665f3;
        /* Change to the desired text color on hover */ }
  .login .form-container {
    width: 30%;
    min-width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 990px) {
      .login .form-container {
        width: 100%;
        min-width: unset;
        padding: 0 1rem; } }
    .login .form-container .password-container,
    .login .form-container .email-container {
      width: 100%;
      transition: opacity .5s ease; }
      @media screen and (max-width: 990px) {
        .login .form-container .password-container,
        .login .form-container .email-container {
          padding: 0 1rem; } }
    .login .form-container .password-input {
      opacity: 0; }
    .login .form-container .trennstrich {
      margin: 1rem 0; }
    .login .form-container .button {
      width: 100%;
      height: 3rem;
      padding: 1rem 0;
      border-radius: 1rem;
      color: white;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
      transition: background .2s ease;
      user-select: none; }
      .login .form-container .button:active {
        background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }

.sign-up {
  min-height: calc(100vh - 6rem - 8rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 5rem; }
  .sign-up .page-header {
    width: 100%;
    font-size: 2.5rem;
    color: white;
    background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 6rem; }
  .sign-up .header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: .5rem;
    padding: 0; }
  .sign-up .text {
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 2rem; }
    @media screen and (max-width: 990px) {
      .sign-up .text {
        width: 100%;
        padding: 0 1rem; } }
  .sign-up .form-container {
    width: 30%;
    min-width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 990px) {
      .sign-up .form-container {
        width: 100%;
        min-width: unset;
        padding: 0 1rem; } }
    .sign-up .form-container .styled-checkbox {
      width: 100%;
      margin-top: 1rem; }
    .sign-up .form-container .button {
      margin-top: 2.2rem;
      width: 100%;
      height: 3rem;
      padding: 1rem 0;
      border-radius: 1rem;
      color: white;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
      transition: background .2s ease;
      user-select: none; }
      .sign-up .form-container .button:active {
        background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
      .sign-up .form-container .button .loading-container {
        height: 1.2rem; }

.profile {
  min-height: calc(100vh - 6rem - 8rem);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 5rem; }
  .profile .tmpbutton {
    position: absolute;
    cursor: pointer;
    color: white;
    left: 2rem;
    background: #579485;
    padding: 1rem 2rem;
    top: 18rem;
    user-select: none;
    border-radius: 1rem; }
    .profile .tmpbutton:active {
      background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
  .profile .page-header {
    width: 100%;
    font-size: 2.5rem;
    color: white;
    background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-bottom: 6rem; }
  .profile .not-logged-in-form-container {
    width: 30%;
    min-width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem; }
    @media screen and (max-width: 990px) {
      .profile .not-logged-in-form-container {
        width: 100%;
        min-width: unset;
        padding: 0 1rem; } }
    .profile .not-logged-in-form-container .form-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .profile .not-logged-in-form-container .form-container .header {
        font-size: 2rem;
        font-weight: bold;
        padding: 0;
        margin-bottom: .5rem; }
      .profile .not-logged-in-form-container .form-container .text {
        text-align: center; }
      .profile .not-logged-in-form-container .form-container .button {
        text-decoration: none;
        margin-top: 2.2rem;
        width: 100%;
        height: 3rem;
        padding: 1rem 0;
        border-radius: 1rem;
        color: white;
        text-align: center;
        cursor: pointer;
        background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
        transition: background .2s ease;
        user-select: none; }
        .profile .not-logged-in-form-container .form-container .button:active {
          background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
  .profile .profile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .profile .profile-container .switch-container {
      width: 60%; }
      @media screen and (max-width: 990px) {
        .profile .profile-container .switch-container {
          width: 100%;
          padding: 0 1rem; } }
      .profile .profile-container .switch-container .option-capsule {
        padding: 1rem 2rem; }
    .profile .profile-container .account-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .profile .profile-container .account-view .header {
        font-weight: bold;
        font-size: 2rem;
        padding-bottom: 0;
        margin-top: 2rem; }
      .profile .profile-container .account-view .detail-grid {
        margin-top: 2rem;
        width: 30%;
        display: flex;
        flex-wrap: wrap;
        gap: .5rem 0; }
        @media screen and (max-width: 990px) {
          .profile .profile-container .account-view .detail-grid {
            width: 100%;
            padding: 0 1rem; } }
        .profile .profile-container .account-view .detail-grid .detail-row {
          width: 100%;
          display: flex;
          justify-content: space-between; }
        .profile .profile-container .account-view .detail-grid .right {
          justify-self: end;
          text-align: end; }
        .profile .profile-container .account-view .detail-grid .edit {
          outline: none;
          border: 1px solid #555555;
          border-radius: 1rem;
          padding: .2rem; }
          .profile .profile-container .account-view .detail-grid .edit:focus {
            outline: #579485; }
      .profile .profile-container .account-view .profile-input-container {
        width: 50%;
        margin-top: 1rem; }
        @media screen and (max-width: 990px) {
          .profile .profile-container .account-view .profile-input-container {
            width: 100%;
            padding: 0 1rem; } }
      .profile .profile-container .account-view .text {
        margin: 1rem 0;
        text-align: center; }
      .profile .profile-container .account-view .logout {
        margin-top: 2rem; }
        .profile .profile-container .account-view .logout:hover {
          color: #579485;
          cursor: pointer; }
      .profile .profile-container .account-view .password-bounds {
        width: 50%; }
        @media screen and (max-width: 990px) {
          .profile .profile-container .account-view .password-bounds {
            width: calc(100% - 2rem);
            padding: 0 1rem; } }
      .profile .profile-container .account-view .button {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-top: 2.2rem;
        width: 50%;
        height: 3rem;
        padding: 1rem 0;
        border-radius: 1rem;
        color: white;
        text-align: center;
        cursor: pointer;
        background: linear-gradient(270deg, #467c6d 0%, #579485 60%);
        transition: background .2s ease;
        user-select: none; }
        @media screen and (max-width: 990px) {
          .profile .profile-container .account-view .button {
            width: calc(100% - 2rem);
            padding: 0 1rem; } }
        .profile .profile-container .account-view .button:active {
          background: linear-gradient(270deg, #2a4e44 0%, #4a7d70 60%); }
    .profile .profile-container .rides-view {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .profile .profile-container .rides-view .tab-switch-container {
        width: 50%; }
        @media screen and (max-width: 990px) {
          .profile .profile-container .rides-view .tab-switch-container {
            width: 100%;
            padding: 0 1rem; } }
      .profile .profile-container .rides-view .rides-container {
        margin-top: 4.75rem;
        padding: 0 10rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem; }
        @media screen and (max-width: 1700px) {
          .profile .profile-container .rides-view .rides-container {
            padding: 0 5rem; } }
        @media screen and (max-width: 990px) {
          .profile .profile-container .rides-view .rides-container {
            padding: 0 1rem; } }
        .profile .profile-container .rides-view .rides-container .loading-container {
          width: 5rem;
          height: 5rem; }
        .profile .profile-container .rides-view .rides-container .ride-container {
          width: 100%; }

.cookie-policy .page-header {
  font-size: 2.5rem;
  color: white;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 3rem; }

.cookie-policy .content {
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .cookie-policy .content {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .cookie-policy .content {
      padding: 0 1rem; } }
  .cookie-policy .content .text {
    text-align: justify;
    margin-bottom: 3rem; }

.legal-notice .page-header {
  font-size: 2.5rem;
  color: white;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 3rem; }

.legal-notice .content {
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .legal-notice .content {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .legal-notice .content {
      padding: 0 1rem; } }
  .legal-notice .content .text {
    text-align: justify;
    margin-bottom: 3rem; }

.terms-and-conditions .page-header {
  font-size: 2.5rem;
  color: white;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 3rem; }

.terms-and-conditions .content {
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .terms-and-conditions .content {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .terms-and-conditions .content {
      padding: 0 1rem; } }
  .terms-and-conditions .content .text {
    text-align: justify;
    margin-bottom: 3rem; }

.privacy-policy .page-header {
  font-size: 2.5rem;
  color: white;
  background: linear-gradient(270deg, #2c584a 0%, #579485 40%);
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 3rem; }

.privacy-policy .content {
  padding: 0 10rem; }
  @media screen and (max-width: 1700px) {
    .privacy-policy .content {
      padding: 0 5rem; } }
  @media screen and (max-width: 990px) {
    .privacy-policy .content {
      padding: 0 1rem; } }
  .privacy-policy .content .text {
    text-align: justify;
    margin-bottom: 3rem; }

html, body {
  height: 100%;
  margin: 0;
  padding: 0; }

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

.content {
  flex-grow: 1; }
